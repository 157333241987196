@import '../../assets/css/vars.less';

.container-icon-button {
  cursor: pointer;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @colors[primary_6];
    border-radius: 0.375rem;
    right: -1rem;
    height: 2rem;
    width: 2rem;

    &:hover {
      background-color: #FF3200;

      svg {
        color: @colors[neutral_1];
      }
    }

    svg {
      color: @colors[primary];
    }
  }

}
@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;