@import "../../../assets/css/vars.less";

.site-layout-header {
  background: #ffffff;
  border-bottom: 1px solid #cbcbcb;
}

.layout-container {
  padding: 20px;
  height: 100%;
}

.ant-layout-header {
  padding: 0 0;
}
.ant-layout-sider-zero-width-trigger {
  display: none;
}

.site-layout-background {
  // background-color: #ffffff;
  // border-radius: 15px;
}

.ant-menu-inline .ant-menu-item::after {
  left: 0;
  width: 0.5rem;
  background-color: @colors[primary];
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-right: none;
}

.ant-menu-inline .ant-menu-item {
  width: 100%;
}

.ant-menu-submenu-inline .ant-menu-submenu::after {
  left: 0;
  width: 0.5rem;
  background-color: @colors[primary];
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-right: none;
}

i.ant-menu-submenu-arrow::after,
i.ant-menu-submenu-arrow::before {
  color: @colors[primary] !important;
}

.ant-menu-light .ant-menu-item span {
  padding-left: 1rem;

  &:hover {
    // background-color: rgba(255, 92, 0, 0.15);
    border-radius: 0.335rem;
  }
}

.customer-selectable-item {
  border: 1px solid #cbcbcb;
  border-radius: 15px;
  min-height: 50px;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px;
}

.ant-menu-submenu-title span {
  padding-left: 1rem;
}

.sidebar {
  padding: 40px 0px;
  background-color: #000000;
  border-radius: 0.75rem;

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(255, 92, 0, 0.15);
    transform: none;
  }

  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background-color: @colors[primary];
  }

  .ant-menu {
    background-color: inherit;
    color: #ffffff;

    .ant-menu-title-content {
      color: inherit;
    }
  }
}

.center-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ant-dropdown-menu {
  border-radius: 0.25rem;
  padding: 1rem;

  div {
    padding-bottom: 0.5rem;
  }

  hr {
    opacity: 0.25;
  }

  :nth-child(3) {
    padding-bottom: 1rem;
  }

  :nth-child(4) {
    padding: 1rem auto;
  }
}

.ant-form-item-explain {
  max-height: 30px;
  overflow: hidden;
}

.customers-list {
  li:nth-child(1) {
    border-bottom: 1px solid #8f93961a;
  }
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;