@import "../../assets/css/vars.less";
@import "../../assets/css/media-queries.less";

.used {
  .splide__pagination__page.is-active {
    margin-left: 150px;
  }

  .ant-modal-content {
    border-radius: 50px;
  }
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;