
@import '../../../../../assets/css/media-queries.less';

.auth-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999;
  background: rgba(20, 20, 20, 0.8);
  visibility: hidden;
  opacity:0;
  transition: opacity 7s ease-in;
  transition-delay: 2s;
}

.auth-modal-active {
  @media @desktop {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: -1;
  }
}

.ant-dropdown ,
.ant-dropdown::before{
  bottom: 1.5rem;
}
@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;