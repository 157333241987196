#typography() {
  .big-title() {
    font-size: 56px;
    font-weight: 800;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: left;
  }

  .normal-title() {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: left;
  }

  .small-title() {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
  }

  .subtitle() {
    font-size: 18px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0px;
    text-align: left;
  }

  .body() {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .body-bold() {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .small-paragraph {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
  }

  .bold-small-paragraph {
    #typography.small-paragraph();
    font-weight: 700;
  }
}
