@import "../../assets/css/vars.less";
@import "../../assets/css/media-queries.less";

.container-home {
  .container-home-benefits {
    margin-top: @margins[smTop];

    .ant-image {
      margin-right: 0.75rem;

      @media @desktop {
        height: 20.125rem;
        margin-right: 0;
      }
    }

    .benefits-divider {
      display: none;
      border-right: 2px solid @colors[primary];
      height: 11.5rem;
      margin: auto 0;

      @media @desktop {
        display: block;
      }
    }

    .benefits-description {
      padding: @paddings[smX];
      max-width: 21.5rem;
      margin: auto 0;

      @media @desktop {
        max-width: none;
        padding-right: 8rem;
      }

      h3 {
        margin-top: @margins[xsTop];

        @media @desktop {
          font-size: 2rem;
        }
      }

      p {
        @media @desktop {
          font-size: 1.125rem;
        }
      }
    }
  }

  .benefits-cards {
    margin: @margins[smY];

    @media @desktop {
      display: flex;
      justify-content: space-between;
      padding: @paddings[lgX];
    }
  }

  .container-home-about-us {
    color: @colors[neutral_1];
    background-color: @colors[secondary];
    padding: @paddings[smY];

    @media @desktop {
      display: flex;
      align-items: center;
      height: 32.9rem;
    }

    .about-description {
      @media @desktop {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        margin-left: 3.5rem;
      }

      .about-intro {
        padding: @paddings[smX];

        @media @desktop {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .ant-row {
        @media @desktop {
          margin-left: 3.5rem;
        }
      }

      .ant-divider {
        display: none;
      }

      @media @desktop {
        display: block;
        border-left: 2px solid @colors[primary];
        height: 19rem;
        margin-left: 8rem;
      }
    }

    h3 {
      color: @colors[neutral_1];

      @media @desktop {
        font-size: 2rem;
      }
    }

    p {
      @media @desktop {
        font-size: 1.125rem;
      }
    }

    .ant-image {
      margin-left: 2.5rem;

      @media @desktop {
        max-height: 19rem;
        max-width: 34.125;
      }
    }
  }

  .container-our-services {
    // background: #f7f7f7;
    padding: @paddings[smY];

    @media @desktop {
      padding: 5rem 8rem;
    }

    h3 {
      margin: 0;
      text-align: center;

      @media @desktop {
        font-size: 2rem;
      }
    }

    .our-services-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .ant-divider {
        margin: 0;
        border-left: 3px solid @colors[primary];
        height: 2.5rem;
        transform: rotate(90deg);
        margin-bottom: 1.5rem;
      }
    }

    .our-services-cards {
      @media @desktop {
        height: 20rem;
        display: flex;
        justify-content: center;
      }

      .our-service-row {
        @media @desktop {
          align-self: center;

          .ant-col {
            .container-service-card {
              margin: 2.8rem 0;
            }
          }
        }
      }

      .item {
        padding: @paddings[smX];
        height: 22rem;

        .container-service-card {
          padding-bottom: 1.5rem;
          margin: 0 1.5rem;
        }
      }
    }
  }

  .splide__arrow svg {
    fill: #fff;
    height: 1.2em;
    width: 1.2em;
  }

  .splide__arrow {
    padding: 40px;
    border-radius: 6px;
    background-color: rgba(255, 50, 0, 0.06);
    // position: fixed;
    // padding: 50px;
    // border-radius: 5px;
    // background-color: red;
  }

  .splide__arrow--next {
    // background-color: #ff3200;
    left: 57%;
    background-color: rgba(255, 50, 0, 0.06);
  }
  .splide__pagination {
    right: 45%;
  }

  .splide__arrow--prev {
    background-color: rgba(255, 50, 0, 0.06);
    // background-color: #ff3200;
    // color: white;
    // background-color: red;
    left: 5%;
  }

  .splide__pagination__page.is-active {
    background-color: #ff3200;
    width: 15px;
    border-radius: 100px;
  }

  .container-carousel-section {
    padding: 3.5rem 1rem;
    overflow-x: hidden;
    background-color: #f7f7f7;

    @media @desktop {
      padding: 5rem 8rem;

      .carousel-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          font-size: 2rem;
        }

        p {
          max-width: 40rem;
          text-align: center;
          font-size: 1.125rem;
        }
      }
    }

    .car-caraousel-container {
      .car-carousel-contain {
        width: 23.5rem;

        .item-carousel {
          margin: 2rem 0rem;
          height: 12.25rem;
        }
      }
    }

    .card-description {
      margin-top: 3rem;

      @media @desktop {
        h5 {
          font-size: 1.5rem;
        }

        p {
          font-size: 1.125rem;
        }
      }

      button {
        margin-top: @margins[xsTop];
      }
    }
  }
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;