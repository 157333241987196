@import "../../../../assets/css/vars.less";
@import "../../../../assets/css/media-queries.less";

.home-banner-container {
  position: relative;
  height: 100vh;

  h1 {
    text-align: center;
    width: 100%;
    font-size: 2.5rem;
    color: @colors[primary];

    @media @tablet {
      font-size: 3.5rem;
    }
  }

  h3 {
    text-align: center;
    width: 100%;
    font-size: 1.5rem;
    color: @colors[neutral_1];

    @media @tablet {
      font-size: 2.5rem;
    }
  }
}

.banner-title-container {
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: @paddings[smX];

  @media @desktop {
    padding: 0;
  }
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;