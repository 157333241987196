.required-none {
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
}

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 50px;
}

.ant-upload-list-item,
.ant-upload-list-item-error,
.ant-upload-list-item-list-type-text {
  height: 50px !important;
}

.ant-modal-confirm-body > .anticon{
  margin-top: 5px;
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;