.container-show-customer{
  &.ant-drawer-body {
    padding: 2rem;
  }
  
  h4, p {
    font-size: .8rem;
  }

  p {
    margin: 0;
  }
  
  .ant-divider {
    margin: .75rem;
  }

  .container-subempresas {
    border-radius: .5rem;
    width: 100%;
    padding: 1.5rem 1.5rem;
    background-color: #FAFAFA;
  }
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;