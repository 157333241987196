@import '../../../../assets/css/vars.less';
@import '../../../../assets/css/media-queries.less';

.container-benefit-card {
  max-width: 20rem;
  min-height: 17rem;
  padding: @paddings[smX];
  .benefit-card-icon{
    width: 3.5rem;
    height: 3.5rem;
    background: rgba(255, 50, 0, 0.06);
    margin-bottom: 1.5rem;
    @media @desktop {
      margin-bottom: 2.25rem;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      svg {
        color: @colors[primary];
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 0.375rem;
      }
    }
  }

  h3 {
    text-align: left;

    @media @desktop {
      height: 4rem;
    }
    
  }

  p {
    @media @desktop {
      font-size: 18px;
    }
  }

}
@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;