.ant-table {
  padding-bottom: 3rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  tr th.ant-table-cell {
    background-color: #ffffff;
  }

  .ant-btn-circle {
    background-color: #ffffff;

    span svg {
      color: #ff5c00;
    }
  }

  .responsive-customer {
    // .ant-table-cell {
    //   min-width: 0px;
    //   max-width: 0px;
    // }
  }

  .ant-btn-circle.disabled-menu {
    border-color: #8c8c8c;

    span svg {
      color: #8c8c8c;
    }
  }
}

// .customers-table-container {
//   .ant-select-selector,
//   .ant-select-arrow {
//     display: none !important;
//   }
// }

.ant-table-row.disabled-row {
  .ant-table-cell:not(:nth-child(7)) {
    cursor: not-allowed !important;
    // pointer-events: none;
  }
}

.disabled-row {
  color: #8c8c8c;
  text-decoration: line-through;
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;