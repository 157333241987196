@import '../../../../assets/css/media-queries.less';
@import '../../../../assets/css/vars.less';

.change-password-form {
  width: 100%;
  padding: 1.25rem 1rem 3.5rem 1rem;
  margin: 0 auto;
  background-color: #FFFFFF;
  border-radius: 10px;

  .ant-input-affix-wrapper {
    border: 0;
    padding: 0 !important;
  }



  .ant-input-suffix {
    position: absolute;
    right: .625rem;
    top: 50%;
    transform: translateY(-50%);
  }


  .ant-input {
    background: #F5F5F5;
    height: 3rem;
    border-radius: 0.5rem;
    padding: 3px 11px;

    @media @desktop {
      height: 2.375rem;
    }
  }


  @media @desktop {
    height: 544px;
    width: 480px;
    margin: 0 auto;
    margin-top: 40px;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 40px;
  }
}
@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;