@import '../../../../assets/css/vars.less';

.car-carousel-contain {
  width: 23.5rem;

  .item-carousel {
    height: 12.25rem;
  }

  .ant-carousel .slick-dots {
    z-index: 10;

    li button {
      width: .5rem;
      height: .5rem;
      transform: matrix(1, 0, 0, -1, 0, 0);
      border-radius: 1.25rem;
      background: @colors[neutral_7];
    }

    li.slick-active button {
      background-color: @colors[primary];
      width: 100%;
    }
  }
}
@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;