@import '../../assets/css/vars.less';

.container-custom-dropdown {
  .ant-dropdown-menu {
    background-color: #000000;

    li {
      color: @colors[neutral_1];

      &:hover {
        color: @colors[primary];
        background-color: inherit;
      }
    }
  }
}
@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;