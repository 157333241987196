@import "../../../../assets/css/vars.less";
@import "../../../../assets/css/media-queries.less";
@import "../../../../assets/css/maps.less";

.visuallyHidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

h1 {
  text-align: center;
}

.container {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.hamburger {
  margin: 0 auto;
  width: 30px;
  height: 30px;
  transform: scale(0.7);
}

.hamburger .bar {
  padding: 0;
  width: 30px;
  height: 4px;
  background-color: @colors[primary];
  display: block;
  border-radius: 4px;
  transition: all ease-in-out 0.4s;
  position: absolute;
}

.bar1 {
  top: 0;
}

.bar2,
.bar3 {
  top: 13.5px;
}

.bar3 {
  right: 0;
}

.bar4 {
  bottom: 0;
}

.hamburger4 .bar4 {
  top: 13.5px;
}

.hamburger4 .bar5 {
  bottom: 0px;
}

.hamburger4 .bar {
  transition: all ease-in-out 0.4s, transform 0.4s ease-in-out 0.4s;
}

.hamburger4 .bar2 {
  width: 1px;
  transform: rotate(90deg);
  left: 13.5px;
}

.hamburger4 .bar3 {
  width: 1px;
  left: 13.5px;
}

.checkbox4:checked + label > .hamburger4 > .bar1 {
  top: 13.5px;
  background-color: transparent;
}

.checkbox4:checked + label > .hamburger4 > .bar2 {
  left: 0px;
  width: 30px;
  transform: rotate(45deg);
}

.checkbox4:checked + label > .hamburger4 > .bar3 {
  left: 0;
  width: 30px;
  transform: rotate(-45deg);
}

.checkbox4:checked + label > .hamburger4 > .bar4 {
  background-color: transparent;
}

.checkbox4:checked + label > .hamburger4 > .bar5 {
  bottom: 13.5px;
  background-color: transparent;
}

// MODAL
.modal {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  // background: rgba(0, 0, 0, .5);
  background: @colors[neutral_11];
  transition: all ease-out 1s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  height: 100vh;
  width: 100%;

  .delimiter {
    height: 4.25rem;
    border-bottom: 1px solid #262626;

    @media @tablet {
      height: 6.25rem;
    }
  }

  .container-modal {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header-modal {
      height: 100%;
      margin: 0 1rem;
      .nav-group {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        & :nth-child(1) {
          margin-top: 1.5rem;
        }

        a {
          color: @colors[neutral_1];
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0.05em;
          text-align: left;
          margin-bottom: 1.5rem;
        }
      }

      .button-group {
        height: 30%;
        display: flex;
        flex-direction: column;

        // height: 30%;

        .talk-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          color: @colors[primary];
          border-radius: 30px;
          height: 3rem;
          width: 100%;

          span {
            svg {
              width: 1rem;
              height: 1rem;
              margin-top: 0.225rem;
            }
          }
        }

        .portal-button {
          height: 3rem;
          width: 100%;
          border-radius: 30px;
          margin-bottom: 1.5rem;
        }
      }
    }

    .footer-modal {
      ul {
        margin: 0 1rem;
        margin-top: 1rem;
        li {
          margin-bottom: 1rem;
          a {
            color: @colors[neutral_1];
          }
        }
      }
    }
  }
}

.modal-active {
  opacity: 1;
  pointer-events: auto;
  z-index: -1;
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;