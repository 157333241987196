@import "../../../assets/css/maps.less";

.title-password-generate {
  #typography.bold-small-paragraph();
}

.info-password-generate {
  #typography.small-paragraph();
}

.profile_image {
  width: 80px;
  height: 80px;
}

.user-date-picker {
  .ant-picker-decade-panel,
  .ant-picker-year-panel,
  .ant-picker-quarter-panel,
  .ant-picker-month-panel,
  .ant-picker-week-panel,
  .ant-picker-date-panel,
  .ant-picker-time-panel {
    width: 400px !important;
  }
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;