.full-bg {
  background-color: rgba(255, 50, 0, 0.1);
}
.without-bg {
  background-color: white;
}

.vehicle-up {
  margin-bottom: 220px;
}

.vehicle-down {
  margin-bottom: 100px;
}
