.container-drawer-executive {

  .ant-form {
    margin-top: 1.8rem;

    .ant-select-selector {
      background-color: #F5F5F5;
    }
  }
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;