@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import "~antd/dist/antd.less";
@import "./assets/css/maps.less";
@import "./assets/css/vars.less";
@import "./assets/css/media-queries.less";

/************** TAGS **************/
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto !important;
}

h1 {
  #typography.big-title();
}
// .ant-picker-decade-panel,
// .ant-picker-year-panel,
// .ant-picker-quarter-panel,
// .ant-picker-month-panel,
// .ant-picker-week-panel,
// .ant-picker-date-panel,
// .ant-picker-time-panel {
//   width: 200px !important;
// }
h2 {
  #typography.normal-title();
}

h3 {
  #typography.small-title();
}

h4 {
  #typography.subtitle();
}

h5 {
  #typography.body-bold();
}

p,
span {
  #typography.body();
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/************** END TAGS **************/

/************** CLASS **************/
.disabled-color {
  color: #8c8c8c;
}

.primary-pointer-text {
  color: #ff5300;
  cursor: pointer;
}

.action-table-button {
  color: #ff5300;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #ff5300;
  border-radius: 50px;
  background-color: rgba(255, 50, 0, 0.08);
}

.ant-drawer-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

/************** END CLASS **************/

/**************  ANTD INPUT **************/

.ant-input {
  border-radius: 8px;
  height: 38px;
  background-color: #f5f5f5;
  border: none;
}

.ant-input::placeholder {
  color: #8c8c8c;
}

.ant-input:focus {
  outline: none;
  box-shadow: none;
}

.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input {
  background-color: #f5f5f5;

  &:hover {
    background-color: #f5f5f5;
  }
}

.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 3px 11px;
}

/************** END ANTD INPUT **************/

/************** ANTD DRAWER **************/
.ant-drawer-content {
  border-radius: 12px 0px 0px 12px;
}

/************** END ANTD DRAWER **************/

/************** ANTD TABLE **************/

.ant-table-column-title,
.ant-table-thead .ant-table-cell {
  #typography.bold-small-paragraph();
}

.ant-table-tbody .ant-table-cell {
  #typography.small-paragraph();
}

/************** END ANTD TABLE **************/

//DatePicker

.ant-picker {
  background: #f5f5f5;
  mix-blend-mode: normal;
  border-radius: 6px;
  width: 200px;
  height: 38px;
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;