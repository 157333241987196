@import '../../assets/css/maps.less';
@import '../../assets/css/vars.less';
@import '../../assets/css/media-queries.less';

.gama-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  height: 2.125rem;
  padding: 0.375rem 1.5rem;
  gap: 0.625rem;
  background-color: @colors[primary];

  @media @desktop {
    padding: .5rem 2rem;
    gap: .5rem;
    height: 2.625rem;
  }

  span {
    #typography.body-bold();
  }


}

.gama-outline {
  .gama-button();
  color: @colors[primary];
  background: transparent;
}
@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;