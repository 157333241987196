@import '../../../assets/css/maps.less';

.title-password-generate {
  #typography.bold-small-paragraph();
}

.info-password-generate {
  #typography.small-paragraph();
}

.password-change-drawer {
  .ant-drawer-header-title button {
    display: none;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem 1.5rem
  }

  .ant-input-affix-wrapper {
    border: 0;
    padding: 0 !important;
  }
  
  .ant-input-suffix {
    position: absolute;
    right: .625rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;