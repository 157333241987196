.container-recovery-form {
  display: block;
  margin-top: 2rem;
  height: 9rem;

  input {
    padding: 8px 12px;
    background: #f5f5f5;
    mix-blend-mode: normal;
    border-radius: 6px;
  }

  button {
    width: 100%;
    padding: 0.5rem 2rem;
    gap: 0.5rem;
    height: 2.625rem;
  }

  .disabled-button {
    pointer-events: none;
    cursor: progress;
  }
}

.container-number {
  display: block;
  margin-top: 2rem;

  input {
    padding: 8px 12px;
    background: #f5f5f5;
    mix-blend-mode: normal;
    border-radius: 6px;
  }

  button {
    width: 100%;
    padding: 0.5rem 2rem;
    gap: 0.5rem;
    height: 2.625rem;
  }

  .disabled-button {
    pointer-events: none;
    cursor: progress;
  }
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;