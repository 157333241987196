.container-vehicles-table {
  //styles
}

//Se agrega especifidad al estilo del dropdown de filtros
.ant-checkbox-group {
  .ant-dropdown-menu {
    max-height: 500px;
    overflow-y: scroll;
  }
}

.ant-select-selector:focus {
  border-radius: 25px;
  outline: none;
  box-shadow: none;
}
.ant-table-pagination {
  flex-wrap: nowrap !important;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  display: none;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  display: none;
}

.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: none !important;
}

.ant-table-pagination > * {
  // flex: none;
  // background-color: rgba(255, 50, 0, 0.2);
  color: black !important;
  border: none;
  // border-radius: 6px;
  // // padding: 1px 7px;
}
.ant-table-row-expand-icon-cell {
  width: 50px;
}

.ant-pagination-options-size-changer.ant-select {
  display: none;
}

.ant-pagination-item-active {
  flex: none;
  background-color: rgba(255, 50, 0, 0.2);
  color: #ff3200;
  border: none;
  border-radius: 6px;
}

.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: #ff3200;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
  background-color: #f5f5f5; //aki
  border: none;
}



@media only screen and (max-width: 400px) {
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 9px!important;
  }
  .ant-table-tbody .ant-table-cell {
    font-size: 11px!important;
  }
}

.dios {
  background-color: "black" !important;
}

.ant-input-group-wrapper.ant-input-search.dios {
  background-color: "black" !important;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  border-radius: 8px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-left: none;
  background-color: #f5f5f5;
  border: none;
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;