@import "../../assets/css/vars.less";
@import "../../assets/css/media-queries.less";

.splide__arrow svg {
  fill: #fff;
  height: 1.2em;
  width: 1.2em;
}

.splide__pagination__page.is-active {
  background-color: #ff3200;
  width: 15px;
  border-radius: 100px;
  margin-top: 20px;
}

.splide__pagination__page {
  margin-top: 20px;
}

.arrow {
  .splide__arrow {
    display: none;
  }
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;