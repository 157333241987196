.menu-invoice-propose {
    background-color: #1F1F1F;
    color: white;
}

.menu-invoice-propose li {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
}

.menu-invoice-propose li:hover {
    color: #FF3200;
    background-color: transparent;
}

.dropdown-menu-invoice-propose {
    display: flex;
    justify-content: right !important;
}

.button-upload-modal .ant-upload.ant-upload-select {
    display: block;
}

.button-upload-modal .gama-button span {
    color: white !important;
}

.button-upload-modal .gama-button:hover,
.button-upload-modal .gama-button:focus,
.button-upload-modal .gama-button:active {
    background-color: #FF3200 !important;
}

.button-upload-modal .gama-button span:hover,
.button-upload-modal .gama-button span:focus,
.button-upload-modal .gama-button span:active {
    color: white !important;
}

.button-more-actions {
    background-color: #FFFFFF;
    border: 1px solid #FF3200;
    border-radius: 36px;
    width: 136px;
    height: 38px;
    color: #FF3200;
}

.button-more-actions:focus,
.button-more-actions:active {
    background-color: #FF3200;
    color: #FFF;
}

.downdown-more-actions {
    width: 160px;
    height: max-content;
    border-radius: 10px;
    background: #1F1F1F;
    box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.10);
}

.options-more-actions {
    display: flex;
    margin: 10px 30px;
    color: #FFF;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    line-height: 22px;
}

.options-more-actions:focus,
.options-more-actions:active,
.options-more-actions:hover  {
    color: #FF3200;
}

.ant-dropdown {
    z-index: 800 !important;
}

@media only screen and (max-width: 576px) {
    .buttons-mobile  {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0px;
        margin-bottom: 10px;
        button {
            width: 175px;
            margin-left: 0px !important;
        }
    }
    
}
@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;