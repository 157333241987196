@import "../../../../assets/css/vars.less";
@import "../../../../assets/css/media-queries.less";

.container-auth-form {
  width: 100vw;

  background-color: rgba(45, 45, 45, 0.5);
  transform: translateY(1.5vh);
  padding: 0 1rem;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 0.5px solid #bfbfbf;
  backdrop-filter: blur(9px);

  .ant-input-affix-wrapper {
    border: 0;
    padding: 0 !important;
  }

  .ant-input-suffix {
    position: absolute;
    right: 0.625rem;
    top: 50%;
    transform: translateY(-50%);
  }

  @media @desktop {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 380px;
    padding: 0 2.5rem;
    overflow: hidden;
  }

  a {
    display: block;
    text-align: center;
    margin-bottom: 2rem;
    color: #262626;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    text-decoration-line: underline;
  }

  .icons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    cursor: pointer;

    @media @desktop {
      height: 3rem;
    }

    .close,
    .arrow {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @colors[primary_6];
        border-radius: 0.375rem;
        right: -1rem;
        height: 2rem;
        width: 2rem;

        &:hover {
          background-color: #cb2800;

          svg {
            color: @colors[neutral_1];
          }
        }

        svg {
          color: @colors[primary];
        }
      }
    }
  }

  .contain-modal-header {
    h3 {
      margin: 0;
      font-size: 18px;
      width: 240px;
      margin-left: -10px;
    }
  }
  .ant-form-vertical .ant-form-item-label > label,
  .ant-col-24.ant-form-item-label > label,
  .ant-col-xl-24.ant-form-item-label > label {
    color: white !important;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none !important;
  }
  .auth-button {
    width: 100%;
    margin: 2.5rem 0;
    height: 2.625rem;

    @media @desktop {
      margin: 1.5rem 0 1rem 0;
    }
  }

  .footer-auth-form {
    display: flex;
    flex-direction: column;

    span,
    a {
      display: inline-block;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
    }

    @media @desktop {
      margin-bottom: 1rem;
    }
  }
}

//Se elimina margen de los mensajes de error
.container-auth-form .form-login-container div :nth-child(2) {
  margin: 0;
  max-height: 1.5rem;
  overflow: hidden;
}

.ant-message {
  position: absolute;
  z-index: 9999;
}

.otp-input-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  margin-top: 32px;
  height: 50px;
}

.otp-input-box {
  width: 40px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  border-radius: 5px;
}

.otp-input-box:focus {
  border-color: #f97316;
  box-shadow: 0 0 5px rgba(249, 115, 22, 0.5);
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;