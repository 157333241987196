@import "../../../../assets/css/media-queries.less";
@import "../../../../assets/css/vars.less";
@import "../../../../assets/css/maps.less";

.header-contain {
  height: 100%;
  // background-image: url('../../../../assets/img/home.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  #navbar {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    transition: top 0.3s;
  }

  html {
    scroll-behavior: smooth;
  }

  .home-nav-container {
    display: none;

    @media @desktop {
      top: 0;
      display: flex;
      justify-content: right;
      align-items: center;
      height: 2.3rem;
      padding: 0 8rem;
      background: rgba(45, 45, 45, 0.8);
      backdrop-filter: blur(8px);

      ul {
        li {
          font-size: 12px;
          display: inline;
          margin-left: 1.5rem;

          a {
            color: @colors[neutral_1];
          }
        }
      }
    }
  }

  &::before {
    background-color: rgba(0, 0, 0, 0.6);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  header {
    background: rgba(20, 20, 20, 0.2);
    backdrop-filter: blur(8px);
    padding: 1rem 20px;

    @media @tablet {
      padding: 2rem 8rem;
    }

    ul {
      background-color: inherit;
      border: none;
    }

    .ant-menu-item::after,
    .ant-menu-submenu::after {
      content: none;
    }

    .ant-menu-item-active:hover {
      background-color: inherit;
    }

    .ant-menu-item span {
      padding-left: 0;
      color: #ffff;
    }

    .home-nav-items {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .group-nav-items {
        display: flex;

        @media @desktop {
          flex-direction: row-reverse;
        }

        .nav-container-buttons {
          display: flex;
          margin-left: auto;
          margin-right: 1rem;

          .talk-button {
            display: none;

            span {
              svg {
                width: 1rem;
                height: 1rem;
                margin-top: 0.225rem;
              }
            }
          }

          // .dropdown-header {
          //   display: none;
          // }

          @media @tablet {
            margin-right: 1rem;
          }

          @media @desktop {
            margin-right: 0;
          }

          @media @tablet {
            .talk-button {
              display: flex;
              margin-right: 0.5rem;
            }
          }
        }

        .container-menu {
          display: flex;
          align-items: center;

          .container-navlinks {
            a {
              color: @colors[neutral_1];
              #typography.body;
              margin-right: 0.5rem;

              @media @desktop {
                margin-right: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}

.header-contain-small {
  height: 115%;
  // background-image: url('../../../../assets/img/home.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  #navbar {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    transition: top 0.3s;
  }

  html {
    scroll-behavior: smooth;
  }

  .home-nav-container {
    display: none;

    @media @desktop {
      top: 0;
      display: flex;
      justify-content: right;
      align-items: center;
      height: 2.3rem;
      padding: 0 8rem;
      background: rgba(45, 45, 45, 0.8);
      backdrop-filter: blur(8px);

      ul {
        li {
          font-size: 12px;
          display: inline;
          margin-left: 1.5rem;

          a {
            color: @colors[neutral_1];
          }
        }
      }
    }
  }

  &::before {
    background-color: rgba(0, 0, 0, 0.6);
    content: "";
    display: block;
    height: 115%;
    position: absolute;
    width: 100%;
  }

  header {
    background: rgba(20, 20, 20, 0.2);
    backdrop-filter: blur(8px);
    padding: 1rem 20px;

    @media @tablet {
      padding: 2rem 8rem;
    }

    ul {
      background-color: inherit;
      border: none;
    }

    .ant-menu-item::after,
    .ant-menu-submenu::after {
      content: none;
    }

    .ant-menu-item-active:hover {
      background-color: inherit;
    }

    .ant-menu-item span {
      padding-left: 0;
      color: #ffff;
    }

    .home-nav-items {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .group-nav-items {
        display: flex;

        @media @desktop {
          flex-direction: row-reverse;
        }

        .nav-container-buttons {
          display: flex;
          margin-left: auto;
          margin-right: 1rem;

          .talk-button {
            display: none;

            span {
              svg {
                width: 1rem;
                height: 1rem;
                margin-top: 0.225rem;
              }
            }
          }

          // .dropdown-header {
          //   display: none;
          // }

          @media @tablet {
            margin-right: 1rem;
          }

          @media @desktop {
            margin-right: 0;
          }

          @media @tablet {
            .talk-button {
              display: flex;
              margin-right: 0.5rem;
            }
          }
        }

        .container-menu {
          display: flex;
          align-items: center;

          .container-navlinks {
            a {
              color: @colors[neutral_1];
              #typography.body;
              margin-right: 0.5rem;

              @media @desktop {
                margin-right: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;