.container-customer-card {
  .ant-avatar-icon {
    background-color: rgba(255, 95, 0, 0.06);
    color: #FF5F00;
    border-radius: 0.25rem;
  }

  h3, span {
    font-size: 1rem;
    margin:0;
  }

}


@primary-color: #FF3200;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;